<template>
  <div class="main-container">
    <div class="menu">
      <div
        :class="selectedTabIndex === index ? 'menu-item-selected' : 'menu-item'"
        v-for="(element, index) in serverTabOptions"
        v-bind:key="index"
        @click="() => switchTab(index)"
      >
        <p>{{ element }}</p>
      </div>
    </div>
    <BuildAutomation v-if="selectedTabIndex === 0" />
    <CreateFleet v-if="selectedTabIndex === 1" :onFleetCreateSuccess="() => switchTab(2)" />
    <ManageAliases v-if="selectedTabIndex === 2" />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import CreateFleet from './components/CreateFleet.vue'
import ManageAliases from './components/ManageAliases.vue'
import BuildAutomation from './components/BuildAutomation.vue'
import { useStore } from "vuex";

export default {
  name: "Server",
  methods: {},
  components: {
      CreateFleet,
      ManageAliases,
      BuildAutomation
  },
  setup() {
    const serverTabOptions = ["Builds & Automation", "Create Fleet", "Fleets & Aliases"];
    const selectedTabIndex = ref(0);

    const store = useStore();

    onMounted(() => {
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const switchTab = (index) => {
      selectedTabIndex.value = index;
    };

    return {
      serverTabOptions,
      selectedTabIndex,
      switchTab
    };
  },
};
</script>


<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.menu-item {
  cursor: pointer;
  border-bottom: 1px solid grey;
  font-size: 15px;
  padding: 10px 20px;
  min-width: 200px;
}

.menu-item:hover {
  background-color: lavender;
}

.menu-item-selected {
  border-bottom: 1px solid grey;
  font-size: 15px;
  background-color: khaki;
  min-width: 200px;
  padding: 10px 20px;
}
</style>