<script>
import { onMounted, ref } from "vue";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import serverManager from "../../../services/server";
import Button from "../../../components/common/Button.vue";

export default {
  name: "Fleet",
  components: {
    Button,
  },
  props: {
    fleet: {
      type: Object,
      required: true,
    },
    onFleetDeleted: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const fleetObject = ref({});
    const isUpdating = ref(false);
    const canBeDeleted = ref(true);

    onMounted(() => {
      fleetObject.value = JSON.parse(JSON.stringify(props.fleet));
      canBeDeleted.value = fleetObject.value.Name.indexOf('prod') === -1 && fleetObject.value.Status === 'ACTIVE'
    });

    const deleteFleet = () => {
      const body = {
        fleet_id: fleetObject.value.FleetId,
      };

      isUpdating.value = true;

      serverManager
        .deleteFleet(body)
        .then(() => {
          notify({
            type: "success",
            text: "Fleet Deleted Successfully",
            title: "SUCCESS",
          });
          props.onFleetDeleted();
        })
        .catch((err) => {
          notify({
            type: "error",
            text: "Fleet Deletion Error: " + err,
            title: "ERROR",
          });
        })
        .finally(() => {
          isUpdating.value = false;
        });
    };

    return {
      fleetObject,
      moment,
      deleteFleet,
      canBeDeleted
    };
  },
};
</script>


<template>
  <div class="fleet-container">
    <div>{{ fleetObject.Name }}</div>
    <div>{{ fleetObject.FleetId }}</div>
    <div>{{ fleetObject.FleetType }}</div>
    <div>
      {{ moment(fleetObject.CreationTime).format("DD/MM/YYYY HH:mm:ss") }}
    </div>
    <div class="cell">
      <div
        class="fleet-status"
        :class="
          fleetObject.Status === 'ACTIVE'
            ? 'active'
            : fleetObject.status === 'ERROR'
            ? 'error'
            : 'default'
        "
      >
        {{ fleetObject.Status }}
      </div>
    </div>
    <div class="cell">
      <Button
        text="Delete"
        type="error"
        :loading="isUpdating"
        :disabled="!canBeDeleted"
        :onClick="() => deleteFleet()"
        size="sm"
      />
    </div>
  </div>
</template>

<style>
.fleet-container {
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  border-bottom: 1px solid grey;
  grid-template-columns: 2fr 3fr 2fr 2fr 2fr 1fr;
  padding: 10px;
  font-size: 15px;
}

.fleet-status {
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  color: white;
  min-width: 150px;
}

.fleet-status.active {
  background-color: darkgreen;
}

.fleet-status.default {
  background-color: #00539cff;
}

.fleet-status.error {
  background-color: darkred;
}

.cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>