<template>
  <div class="container">
    <div class="main-heading">
      <h2>Aliases</h2>
    </div>
    <div v-if="aliases.length > 0" class="alias-header">
      <div>Alias Name</div>
      <div>Alias Id</div>
      <div>Associated Fleet Name</div>
    </div>
    <div>
      <Alias
        v-for="(alias, index) in aliases"
        v-bind:key="index"
        :alias="alias"
        :fleetOptions="fleets"
        :onAliasUpdate="() => fetchData()"
      />
    </div>

    <div class="table-container">
      <div class="main-heading">
        <h2>Fleets</h2>
      </div>
      <div v-if="fleets.length > 0" class="fleet-header">
        <div>Fleet Name</div>
        <div>Fleet Id</div>
        <div>Fleet Type</div>
        <div>Creation Time</div>
        <div>Status</div>
      </div>
      <div>
        <Fleet
          v-for="(fleet, index) in fleets"
          v-bind:key="index"
          :fleet="fleet"
          :onFleetDeleted="() => fetchData()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import serverManager from "../../../services/server";
//import Button from "../../../components/common/Button.vue";
//import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import Alias from "./Alias.vue";
import Fleet from "./Fleet.vue";

export default {
  name: "ManageAliases",
  methods: {},
  components: {
    Alias,
    Fleet,
  },
  setup() {
    const store = useStore();

    const aliases = ref([]);
    const fleets = ref([]);

    onMounted(() => {
      fetchData();
    });

    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const fetchData = () => {
      aliases.value = [];
      fleets.value = [];
      store.dispatch("loader/loadingStatus", true);
      Promise.all([serverManager.getAliases(), serverManager.getFleets()])
        .then(([aliasResult, fleetResult]) => {
          aliases.value = aliasResult;
          fleets.value = fleetResult.sort((a, b) => new Date(b.CreationTime) - new Date(a.CreationTime));
        })
        .catch((err) => console.log(err))
        .finally(() => store.dispatch("loader/loadingStatus", false));
    };

    return {
      aliases,
      fleets,
      fetchData,
    };
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.container {
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 30px;
}

.main-heading {
  width: 100%;
  margin-left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alias-header {
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  border-bottom: 1px solid grey;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
}

.fleet-header {
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  border-bottom: 1px solid grey;
  grid-template-columns: 2fr 3fr 2fr 2fr 2fr 1fr;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
}

.table-container {
  margin-top: 40px;
}
</style>