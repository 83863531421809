<template>
  <div class="section-container">
    <div class="section">
      <div class="section-header">
        <h3>Trigger Pipeline</h3>
        <Button
          type="success"
          :loading="isLoading"
          :onClick="triggerPipeline"
          text="Trigger"
          :disabled="
            selectedTargets.length === 0 ||
            !selectedBranch ||
            !selectedEnvironment ||
            serverBuildVersion === ''
          "
          size="md"
        />
      </div>
      <div class="section-body">
        <div class="section-row">
          <div class="col">
            <h4>Build</h4>
            <div class="form-field">
              <p>Build Targets</p>
              <multiselect
                class="input-multi"
                v-model="selectedTargets"
                label="name"
                track-by="id"
                :options="buildTargets"
                :multiple="true"
              />
            </div>
            <div class="form-field">
              <p>Branch</p>
              <multiselect
                class="input-multi"
                v-model="selectedBranch"
                label="name"
                track-by="name"
                :options="branches"
                :multiple="false"
              />
            </div>
            <div class="form-field">
              <p>Server Environment (for server build variables)</p>
              <multiselect
                class="input-multi"
                v-model="selectedEnvironment"
                label="name"
                track-by="name"
                :options="environmentOptions"
                :multiple="false"
              />
            </div>
            <div class="form-field">
              <p>Server Build Version</p>
              <input class="input" v-model="serverBuildVersion" />
            </div>
          </div>
          <div class="col">
            <h4>Deployment</h4>
            <div class="form-field">
              <p>Deployment Targets</p>
              <multiselect
                class="input-multi"
                v-model="selectedDeploymentTargets"
                label="name"
                track-by="id"
                :options="deploymentTargets"
                :multiple="true"
              />
            </div>
            <div
              class="form-field"
              v-if="selectedDeploymentTargets.find((x) => x.id === 'android')"
            >
              <p>App Version</p>
              <input class="input" v-model="androidDeploymentVersion" />
            </div>
            <div
              class="form-field"
              v-if="selectedDeploymentTargets.find((x) => x.id === 'android')"
            >
              <p>Android Build Number</p>
              <input class="input" v-model="androidDeploymentBuildNumber" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-header">
        <h3>Active Pipelines</h3>
        <Button
          type="success"
          :loading="isRefreshing"
          :onClick="() => getActivePipelines()"
          text="Refresh"
          size="md"
        />
      </div>
      <div class="section-body">
        <div class="pipeline-row">
          <p class="cell header">{{ "Branch" }}</p>
          <p class="cell header">{{ "Status" }}</p>
          <p class="cell header">{{ "Creation Date" }}</p>
          <p class="cell header">{{ "Actions" }}</p>
        </div>
        <div
          v-for="(pipeline, index) in activePipelines"
          v-bind:key="index"
          class="pipeline-row"
        >
          <p class="cell">{{ pipeline.ref }}</p>
          <div class="cell">
            <div :class="'color-bg-text' + ' ' + pipeline.status">
              <p style="margin: 0px;" >{{ pipeline.status === 'manual' ? 'blocked' : pipeline.status }}</p>
            </div>
          </div>
          <p class="cell">
            {{ moment(pipeline.created_at).local().format("DD/MM/YYYY HH:mm") }}
          </p>
          <div class="cell" style="display: flex; flex-direction: row">
            <p
              style="margin-right: 5px"
              class="link"
              @click="() => openPipelineOnGitLab(pipeline.web_url)"
            >
              {{ "GitLab" }}
            </p>
            <Button
              type="error"
              :loading="false"
              :onClick="() => cancelPipeline(pipeline.id)"
              text="Cancel"
              size="sm"
              :disabled="!['pending', 'running'].includes(pipeline.status)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Button from "../../../components/common/Button.vue";
import { useStore } from "vuex";
import buildService from "../../../services/build";
import {
  BUILD_TARGETS,
  ENVIRONMENT_CONSTANTS,
} from "../../../constants/serverConstants";
import { notify } from "@kyvg/vue3-notification";
import moment from "moment";

export default {
  name: "BuildAutomation",
  methods: {},
  props: {},
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const isRefreshing = ref(false);

    //#region internal state

    const selectedEnvironment = ref(null);
    const selectedBranch = ref(null);
    const selectedTargets = ref([]);
    const selectedDeploymentTargets = ref([]);
    const serverBuildVersion = ref("");
    const androidDeploymentVersion = ref("");
    const androidDeploymentBuildNumber = ref("");

    const branches = ref([]);
    const activePipelines = ref([]);

    //#endregion

    onMounted(() => {
      store.dispatch("loader/loadingStatus", false);
      getAvailableBranches();
      getActivePipelines();
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const refreshState = () => {
      activePipelines.value = [];
      getActivePipelines();
    };

    const getAvailableBranches = () => {
      buildService
        .getAllBranches()
        .then((response) => {
          branches.value = response.map((x) => {
            return { id: x, name: x };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getActivePipelines = () => {
      isRefreshing.value = true;
      buildService
        .getActivePipelines()
        .then((response) => {
          activePipelines.value = response;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (isRefreshing.value = false));
    };

    const openPipelineOnGitLab = (url) => {
      window.open(url);
    };

    const validateTrigger = () => {
      if (
        selectedDeploymentTargets.value.find((x) => x.id === "android") &&
        !selectedTargets.value.find((x) => x.id === "android-prebuild")
      ) {
        notify({
          type: "error",
          title: "Error",
          text: "You cannot run Android deployment without Android build.",
        });
        return false;
      }

      if (
        selectedDeploymentTargets.value.find((x) => x.id === "ios") &&
        !selectedTargets.value.find((x) => x.id === "ios-prebuild")
      ) {
        notify({
          type: "error",
          title: "Error",
          text: "You cannot run iOS deployment without iOS build.",
        });
        return false;
      }

      return true;
    };

    const triggerPipeline = () => {
      if (!validateTrigger()) return;

      const reqBody = {
        branch: selectedBranch.value.id,
        selected_builds: selectedTargets.value.map((x) => x.id),
        environment: selectedEnvironment.value.id,
        version: serverBuildVersion.value,
        android_deployment: selectedDeploymentTargets.value.find(
          (x) => x.id === "android"
        )
          ? true
          : false,
        ios_deployment: selectedDeploymentTargets.value.find(
          (x) => x.id === "ios"
        )
          ? true
          : false,
        android_version: androidDeploymentVersion.value,
        android_build_number: androidDeploymentBuildNumber.value,
      };

      isLoading.value = true;

      buildService
        .triggerPipeline(reqBody)
        .then(() => {
          notify({
            type: "success",
            title: "Success",
            text: "Job Triggered Successfully",
          });
          refreshState();
        })
        .catch((err) => {
          notify({
            type: "error",
            title: "Trigger Error",
            text: err.toString(),
          });
        })
        .finally(() => (isLoading.value = false));
    };

    const cancelPipeline = (pipelineId) => {
      const reqBody = {
        pipeline_id: pipelineId,
      };

      buildService
        .cancelPipeline(reqBody)
        .then(() => {
          notify({
            type: "success",
            title: "Success",
            text: "Pipeline Cancelled Successfully",
          });
          refreshState();
        })
        .catch((err) => {
          notify({
            type: "error",
            title: "Error",
            text: err.toString(),
          });
        });
    };

    return {
      isLoading,
      getAvailableBranches,
      selectedBranch,
      branches,
      selectedDeploymentTargets,
      deploymentTargets: [
        { id: "ios", name: "iOS" },
        { id: "android", name: "Android" },
      ],
      buildTargets: BUILD_TARGETS,
      selectedTargets,
      triggerPipeline,
      activePipelines,
      openPipelineOnGitLab,
      moment,
      getActivePipelines,
      isRefreshing,
      selectedEnvironment,
      environmentOptions: ENVIRONMENT_CONSTANTS,
      serverBuildVersion,
      cancelPipeline,
      androidDeploymentBuildNumber,
      androidDeploymentVersion,
    };
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid grey;
}

.section-header {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section-body {
  display: flex;
  flex-direction: column;
}

.section-row {
  display: flex;
  flex-direction: row;
}

.col {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.input-multi {
  width: 300px;
  height: 35px;
}

.form-field {
  margin: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pipeline-row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 100px;
  overflow: hidden;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
}

.cell.header {
  font-weight: bold;
}

.link {
  color: blue;
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.color-bg-text {
  display: flex;
  padding: 4px 7px;
  max-height: 18px; 
  border-radius: 20px;
  color: white;
  text-overflow: ellipsis;
}

.color-bg-text.success {
  background-color: green;
}

.color-bg-text.failed {
  background-color: darkred;
}

.color-bg-text.pending {
  background-color: rgb(244, 170, 73);
}

.color-bg-text.running {
  background-color: darkcyan;
}

.color-bg-text.canceled {
  background-color: rgb(255, 98, 0);
}

.color-bg-text.manual {
  background-color: darkgray;
}

</style>