import getAxios from './axios'

export default {
    getAllBranches: () => {
        console.log("HERERERERERERERE")
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/builds/branches').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    triggerPipeline: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/builds/pipeline/trigger', data).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    cancelPipeline: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/builds/pipeline/cancel', data).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getActivePipelines: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/builds/pipelines/active').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getAliases: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/aliases').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getBuilds: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/builds').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getFleets: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/fleets').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    updateAlias: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server/update-alias', data).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
}