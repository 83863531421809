<script>
import { onMounted, ref, reactive } from "vue";
import Button from "../../../components/common/Button.vue";
import serverManager from '../../../services/server'
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "Alias",
  components: {
    Button,
  },
  props: {
    alias: {
      type: Object,
      required: true,
    },
    fleetOptions: {
      type: Array,
      required: true,
    },
    onAliasUpdate: {
        type: Function,
        required: true
    }
  },
  setup(props) {
    const aliasObject = ref({});
    const fleets = ref([]);
    const currentFleet = ref();

    const isUpdating = ref(false);

    onMounted(() => {
      aliasObject.value = JSON.parse(JSON.stringify(props.alias.Alias));
      fleets.value = JSON.parse(
        JSON.stringify(props.fleetOptions.filter((x) => x.Status === "ACTIVE"))
      );
      currentFleet.value = {
        id: aliasObject.value.RoutingStrategy.FleetId,
        name: fleets.value.find(
          (x) => x.FleetId === aliasObject.value.RoutingStrategy.FleetId
        )?.Name,
      };
    });

    const state = reactive({
      aliasId: props.alias.AliasId,
    });

    const submitAliasUpdate = () => {
        const body = {
            alias_id: aliasObject.value.AliasId,
            fleet_id: currentFleet.value.id
        }

        isUpdating.value = true;

        serverManager.updateAlias(body)
            .then(() => {
                notify({
                    type: "success",
                    text: "Alias Updated Successfully",
                    title: "SUCCESS",
                });
                props.onAliasUpdate();
            }).catch(err => {
                notify({
                    type: "error",
                    text: "Fleet Creation Error: " + err,
                    title: "ERROR",
                });
            }).finally(() => {
                isUpdating.value = false
            })
    };

    return {
      state,
      aliasObject,
      currentFleet,
      fleets,
      submitAliasUpdate,
      isUpdating,
    };
  },
};
</script>


<template>
  <div class="alias-container">
    <div>{{ aliasObject.Name }}</div>
    <div>{{ aliasObject.AliasId }}</div>
    <multiselect
      class="input-multi"
      v-model="currentFleet"
      :options="
        fleets.map((x) => {
          return { id: x.FleetId, name: x.Name };
        })
      "
      label="name"
      track-by="name"
      :multiple="false"
    />
    <div class="cell">
      <Button
        text="Update"
        type="success"
        :loading="isUpdating"
        :disabled="currentFleet?.id === aliasObject?.RoutingStrategy?.FleetId"
        :onClick="() => submitAliasUpdate()"
        size="md"
      />
    </div>
  </div>
</template>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.input-multi {
  height: 25px;
}

.cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alias-container {
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  border-bottom: 1px solid grey;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px;
  font-size: 15px;
}
</style>