module.exports = {
    SERVER_REGIONS: [{
        id: "eu-central-1",
        name: "eu-central-1"
    },
    {
        id: "us-east-1",
        name: "us-east-1"
    },
    {
        id: "ap-southeast-1",
        name: "ap-southeast-1"
    },
    {
        id: "sa-east-1",
        name: "sa-east-1"
    }],
    BUILD_TARGETS: [
        {
            id: 'dedicatedserver-prebuild',
            name: 'Dedicated Server'
        },
        {
            id: 'ios-prebuild',
            name: 'iOS'
        },
        {
            id: 'android-prebuild',
            name: 'Android'
        }
    ],
    ENVIRONMENT_CONSTANTS: [
        {
            id: 'dev',
            name: 'Development'
        },
        {
            id: 'staging',
            name: 'Staging'
        },
        {
            id: 'prod',
            name: 'Production'
        }
    ]
}