<template>
  <div class="first-section">
    <div class="col">
      <div class="form-field">
        <h4>Fleet Name</h4>
        <input class="input" v-model="state.name" />
      </div>
      <div class="form-field">
        <h4>Build Id</h4>
        <input class="input" v-model="state.buildId" />
      </div>
      <div class="form-field">
        <h4>Instance type</h4>
        <input class="input" v-model="state.instance" />
      </div>
      <div class="form-field">
        <h4>Process Count</h4>
        <input class="input" v-model="state.processCount" type="number" />
      </div>
      <div class="form-field">
        <h4>Metric Group</h4>
        <input class="input" v-model="state.metricGroup" />
      </div>
      <div class="form-field">
        <h4>Locations</h4>
        <multiselect
          class="input-multi"
          v-model="state.regions"
          :options="serverRegions"
          label="name"
          track-by="name"
          :multiple="true"
        />
      </div>
      <div class="form-field-row">
        <input type="checkbox" id="spot" v-model="state.isSpotFleet" />
        <label for="spot">{{ "Is Spot Fleet" }}</label>
      </div>
      <div class="form-field-row">
        <input type="checkbox" id="protection" v-model="state.fullProtection" />
        <label for="protection">{{ "Full Protection" }}</label>
      </div>
      <div class="form-field">
        <Button
          type="success"
          :loading="isLoading"
          :onClick="submit"
          text="Create Fleet"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onBeforeUnmount } from "vue";
import { SERVER_REGIONS } from "../../../constants/serverConstants";
import serverManager from "../../../services/server";
import Button from "../../../components/common/Button.vue";
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";

export default {
  name: "CreateFleet",
  methods: {},
  props: {
      onFleetCreateSuccess: {
          type: Function,
          required: true
      }
  },
  components: {
    Button,
  },
  setup(props) {
    const store = useStore();
    onMounted(() => {
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    const isLoading = ref(false);

    const state = reactive({
      name: "",
      isSpotFleet: false,
      regions: [],
      processCount: 1,
      buildId: "",
      metricGroup: "staging",
      fullProtection: true,
      instance: "c5.2xlarge",
    });

    const resetState = () => {
      state.fullProtection = true;
      state.name = "";
      state.isSpotFleet = false;
      state.regions = [];
      state.processCount = 1;
      state.buildId = "";
      state.metricGroup = "staging";
    };

    const submit = () => {
      const body = {
        name: state.name,
        spot: state.isSpotFleet,
        process_count: state.processCount,
        build_id: state.buildId,
        locations: state.regions.map((x) => x.id),
        metric_group: state.metricGroup,
        full_protection: state.fullProtection,
        instance: state.instance,
      };
      isLoading.value = true;
      serverManager
        .createFleet(body)
        .then(() => {
          notify({
            type: "success",
            text: "Fleet Created Successfully",
            title: "SUCCESS",
          });
          isLoading.value = false;
          resetState();
          props.onFleetCreateSuccess()
        })
        .catch((err) => {
          notify({
            type: "error",
            text: "Fleet Creation Error: " + err,
            title: "ERROR",
          });
          isLoading.value = false;
        });
    };

    return {
      isLoading,
      state,
      serverRegions: SERVER_REGIONS,
      submit,
    };
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>

.content-container {
  width: 100%;
}

.form-field {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-field-row {
  margin: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.status-circle-success {
  margin-left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: green;
}

.status-circle-fail {
  margin-left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: red;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.input-multi {
  width: 300px;
  height: 35px;
}

.first-section {
    margin-left: 50px;
  padding: 10px 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}
</style>